import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Dropdown from "../components/Dropdown"
import Seo from "../components/seo"
import { Form, Button } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import NumberTable from '../components/NumberTable'
import { Modal } from '@themesberg/react-bootstrap';
import useAuth from "../hooks/userAuth";
import DatePicker from "../components/DatePicker"
const { useState } = React;

const IndexPage = () => {

    const location = useLocation();
    const qs = new URLSearchParams(location.search)
    const { isLogin } = useAuth()
    const [items, setItems] = useState([])
    const [id, setId] = useState(qs.get('id') || "")
    const [item, setItem] = useState({})
    const [dataString, setDataString] = useState("{}")
    const [suggestion, setSuggestion] = useState([])
    const [displayInfo, setDisplayInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [isSuggestionLoading, setSuggestionLoading] = useState(false)
    const [types, setTypes] = useState([])
    const [selectedType, setSelectedType] = useState(null)
    const [attributePayload, setAttributePayload] = useState({})
    const [typeName, setTypeName] = useState("")
    const [attributeName, setAttributeName] = useState("")
    const [numOfAttribute, setNumOfAttribute] = useState(5)
    const [attributeValue, setAttributeValue] = useState([
        { label: 'string', value: 'string' },
        { label: 'array', value: 'array' },

    ])
    const [attributeArray, setAttributeArray] = useState([])
    const [selectedAttribute, setSelectedAttribute] = useState(attributeValue[0])
    React.useEffect(() => {
        if (types.length) {
            setSelectedType(types[0])
        }
    }, [types])
    const handleSave = async (e) => {
        console.log(payload)
        Parse.Cloud.run('updateItem', {
            objectId: id,
            model: {
                name: item?.name,
                type: item?.type,
                data: JSON.parse(dataString)
            }
        })
    }

    const getAllTypes = async (e) => {
        if (id) {
            const res = await Parse.Cloud.run('queryItems', {
                query: {
                    equalTo: ['objectId', id]
                }
            })
            setTypes(res)
            setItem(res[0])
            setDataString(JSON.stringify(res[0]?.data, null, 4))
        }

    }

    const searchNumbers = async (query) => {
        setIsLoading(true)
        const res = await getItems()

        setIsLoading(false)
    }

    const getItems = async () => {
        return await Parse.Cloud.run('getItemsMeta')
    }

    const notWhatsppFlow = ['admin', 'user'].includes(isLogin?.role)
    const getMySuggestion = async () => {
        setSuggestionLoading(true)
        const res = await Parse.Cloud.run('getMySuggestedNumber')

        setSuggestion(res.suggestion)
        setSuggestionLoading(false)
    }

    React.useEffect(() => {
        getAllTypes()

    }, [])



    const handleItem = async (item) => {

        if (notWhatsppFlow) {
            const res = await Parse.Cloud.run('querySpecificNumber', {
                _id: item._id
            })

            setDisplayInfo(<>
                {res.num} is detected at  {res.sourceId} on {res.date}
                <br />
                <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=Find Mobile Number`}>whatsapp</a> us if you need help getting the number
            </>)
        } else {
            const url = `https://api.whatsapp.com/send?phone=6587413159&text=` + encodeURIComponent(`Enquiry on ${item.num} with id ${item._id}`)
            navigate(url)
        }

    }

    const handleAddAttribute = async () => {
        const res = await Parse.Cloud.run('addAttribute', {
            typeName: selectedType?.name,
            attributeObject: {
                [attributeName]: selectedAttribute?.value
            }
        })
    }

    const handleClose = () => {
        setDisplayInfo(null)
    }

    const cols = [
        { label: 'id', key: 'objectId' },
        { label: 'Type', key: 'type' },
        { label: 'Name', key: 'name' },
        {
            label: 'Action', onRender: (item) => {
                return <Button onClick={() => handleItem(item)}>
                    <FontAwesomeIcon icon={faEye} />
                </Button>
            }
        }]

    return <Layout afterSearch={<></>} >
        <h3 className="text-center">Management</h3>
        <h3>Create Item</h3>
        {
            JSON.stringify(item)
        }
        <Form>

            <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Control type="Text"
                    value={item?.type}
                    onChange={(e) => setItem({
                        type: e.target.value,
                        ...item
                    })}

                />
            </Form.Group>
            <Form.Group className="mb-3">

                <Form.Label>Name</Form.Label>
                <Form.Control
                    value={item?.name}
                    type="Text"
                    onChange={(e) => setItem({
                        name: e.target.value,
                        ...item
                    })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={10}

                    value={dataString}

                    onChange={(e) => setDataString(e.target.value)}
                ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
                <Button onClick={() => handleSave()}> {item ? "Update" : "Create"} </Button>
            </Form.Group>
        </Form>

        <h3>Add Attribute</h3>

        <Form>

            <Form.Group className="mb-3">
                <Form.Label>Key</Form.Label>
                <Form.Control type="Text" onChange={(e) => setAttributeName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Value</Form.Label>
                <Form.Control type="Text" onChange={(e) => setAttributeName(e.target.value)} />

            </Form.Group>
            <Button onClick={() => {
                setAttributeArray([
                    { [attributeName]: "string" },
                    ...attributeArray
                ])
                setAttributeName("")
            }}> Add Attribute </Button>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={10} value={JSON.stringify(selectedType?.data, null, '\t')} ></Form.Control>
            </Form.Group>
        </Form>
    </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
